import React,{useEffect, useState} from 'react'
import Development from '../development-card'
import Loading from '../Loading'
import { connect } from 'react-redux'
import {getDevelopmentsFilterAction, updateFiltersDevelopment} from '../../redux/developmentsDucks'
import { getReverseOrder } from '../../helpers/helper.developments'

const Developments = ({developmentsFilters,loading,api_key,dispatch}) => {

    const [filter,setFilter] = useState(0)
    const [buttons,setButtos] = useState([
        {
            name:"VER TODOS",
            value:0
        },
        {
            name:"EN POZO",
            value:3
        },
        {
            name:"EN CONSTRUCCIÓN",
            value:4
        },
        {
            name:"TERMINADOS",
            value:6
        },

    ])

    useEffect(() => {
        // console.log(api_key)
        if(api_key){	
            dispatch(updateFiltersDevelopment(filter))
            dispatch(getDevelopmentsFilterAction()) // Get all developments
        }
    },[filter,api_key])

    return  (
        <section id="featured-devs" class="pt-lg-5">
            {/* <div className="container-fluid my-5 pb-lg-3">
                <div className="d-flex flex-wrap justify-content-center">
                    {buttons.map((button,index) => (
                        <button className={'button-filter mb-2 mb-lg-0 ' + (filter === button.value ? ' active ' : '')} onClick={() => setFilter(button.value)}>{button.name}</button>
                    ))}
                </div>
            </div> */}
            {
            developmentsFilters.data && !loading ?
                <div class="container-fluid">
                    <div class="row no-gutters mt-5 mt-lg-1" >
                        {
                            getReverseOrder(developmentsFilters.data.objects).map((development,index) => (
                                <div class="col-lg-4 mb-3 mb-lg-4 pb-lg-2 px-lg-3">
                                    <Development key={index} development={development} />
                                </div>                  
                            ))
                        }
                    </div>
                </div>
            :<Loading />
            }
        </section> 
    )
}
export default connect(state => ({
    developmentsFilters: state.developments.developmentsFilters,
    loading: state.developments.loading,
    api_key: state.settings.keys.tokko

}),null)(Developments);