import * as React from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"

//Components
import Main from '../../components/Emprendimientos/main'
import About from '../../components/Emprendimientos/about'
import Steps from '../../components/Emprendimientos/steps'
import Investors from '../../components/investors'

const IndexPage = () => (
  <Layout>
    <Seo title="Emprendimientos" description="Aquí debería ir la descripción de la sección de emprendimientos" />
    <About />
    <Investors />
    <Steps />
  </Layout>
)

export default IndexPage
